@use 'styles/abstracts' as *;

.emailCaptureContainer {
  //width: 1440px;
  min-height: 563px;
  flex-shrink: 0;
  background: #e2e2e2;
  position: relative;
  justify-content: center;
  display: flex;

  .deselectorEmail,
  .deselectorEmailCaptureCheckbox {
    flex: 1 1 100% !important;
  }

  .testfname{
    flex: 0.5;
  }

  .deselectorEmailCaptureCheckbox {
    display: inline-flex;
    align-items: center;
    padding: 0 20px 0 20px;
    background-color: none;
    fieldset {
      border: none;
      background-color: none;
      label: {
        border: none;
        // .deselectorEmailCaptureCheckbox input[type="checkbox"] {
        //   accent-color: none !important;
        // }
      }
      .CheckboxGroup_primary.CheckboxGroup_dirty {
        border: none;
      }

      .wrapper-error {
        border: none;
      }
    }
    input {
      border: 1px solid black;
      border-radius: 2px;
      width: 24px;
      accent-color: black;
      min-width: 30px !important;
      height: 25px;
    }

    span {
      //color: var(--Neutral-Black, #000);
      font-feature-settings: "liga" off, "clig" off;
      font-family: "HCo Gotham SSm", Helvetica, Arial, "Lucida Grande",
        sans-serif;
      font-weight: 350;
      font-style: normal;
      font-weight: 325;
      line-height: 20px; /* 125% */
      text-align: left;
    }

    label:hover {
      background-color: #e2e2e2 !important;
    }
  }

  .content {
    @include grid;
    
    margin-inline: rem(26);
  
  @include breakpoint(xlarge) {
    margin-inline: rem(0);
    max-width: rem(1150);
  }

    max-width: rem($content-width);
    padding: rem(50) 0;
    position: relative;
    width: 100%;

    div {
      @include grid-column(1, span 6);

      text-align: center;
      margin-top: 10px;

      @include breakpoint(medium) {
        @include grid-column(4, span 6);
      }

      div > span {
        // color: #eb002a !important;
        padding-top: 0px;
      }
    }

    .headline {
      margin-bottom: $element-v-spacing-lg;
    }

    .description {
      @extend .common-description;

      // margin-bottom: $element-v-spacing-xl;
    }

    div > form > div > div > ul {
      display: none;
    }

    div > form > div > div > div > span {
      color: #eb002a;
    }

    .popupBackground {
      position: fixed;
      top: 100px;
      left: 0;
      width: 105vw;
      height: 20vw;
      //background: rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      z-index: 9999;
      padding-bottom: 10px;
    }
    .popContainer {
      position: relative;
      background: #fff;
      padding: 75px 50px;
      border-radius: 10px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      text-align: center;
    }

    .popContainer h3 {
      margin: 0 0 10px;
      color: green;
    }

    .closePopup {
      position: absolute;
      top: 10px;
      right: 15px;
      background: transparent;
      border: none;
      font-size: 24px;
      cursor: pointer;
      color: #333;
    }

    .closePopup:hover {
      color: red;
    }
  }

  .formcontainer {
    @include grid-column(1, span 6);

    position: relative;
    z-index: 1;

    @include breakpoint(medium) {
      @include grid-column(1, span 12);
    }

    form > div > div {
      .errors-container-deselector {
        display: none !important;
      }
    }
    
    form > div > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;

      div > div {
        margin-top: -4% !important;
      }

      div > div {

        @media (max-width: 767px) {
          margin-left: 7% !important;
          min-width: max-content !important;
        }
      }
      

      fieldset {
        border: none !important;
      }

      div {
        //margin-bottom: $element-v-spacing-md;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        label {
          width: 100%;
          margin-bottom: 5px;
          flex: 1 1 33%;
          min-width: 100px;
          display: inline-flex;
          align-items: center;
        }

        #email-capture-form-email-wrapper {
          flex: 1 1 100%;
        }
      }

      button {
        min-height: rem(60);
      }

      @include breakpoint(medium) {
        align-items: center;

        input {
          min-width: rem(100);
        }
      }

      @include breakpoint(large) {
        flex-direction: row;

        button {
          margin-left: $element-spacing-md;
          margin-top: rem(9);
        }
      }

      @include breakpoint(large) {
        align-items: flex-start;
        column-gap: 10px;
      }

      label{
        span {
          span{
          padding: 1.125rem 0rem 0 0rem !important;
          font-size: 0.9rem;
          }
        }
      }
    }
  }
}

